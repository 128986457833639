<template>
  <div class="v_my_team g-flex-column">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>

      <template v-slot:default>
        <span>我的推广订单</span>
      </template>
    </head-back>
    
    <div class="v-my-team-container g-flex-column">

      <ul class="v-my-team-head-list g-flex-align-center">
        <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">时间</span>
        </li>
         <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">姓名</span>
        </li>
         <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">订单金额</span>
        </li>
        <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">佣金</span>
        </li>
      </ul>
      <div class="v-my-team-list-container">
          <van-list class="v-my-team-list" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
            <div class="v-my-team-list-item g-flex-column" v-for="(item, index) in num" :key="index">
              <div class="v-my-team-list-item-box g-flex-align-center">
                <p class="v-my-team-list-item-val g-flex-justify-center">2020-04-25</p>
                <p class="v-my-team-list-item-val g-flex-align-center g-flex-justify-center">网友TJX32</p>
                <p class="v-my-team-list-item-val g-flex-justify-center">3</p>
                <p class="v-my-team-list-item-val g-flex-justify-center">0.5</p>
              </div>
            </div>

          </van-list>
      </div>
    </div>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { PAGE, LIMIT } from '@/utils/constants.js'

  export default {
    components: { HeadBack },
    data() {
      return {
        num: 10,
        form: {
          page: PAGE,
          limit: LIMIT,
        },
        loading: false,
        finished: false
      }
    },
    methods: {
      onLoad() {
        this.form.page++
        this.apiGetMessageHandel()
      },
      async apiGetMessageHandel() {
        // const { success, data } = await apiGetCashList(this.form)
        // if(!success) return
        // this.CashOutHistoryList = this.CashOutHistoryList.concat(data.list)
        setTimeout(() => {
          this.num+=5
          this.loading = false
          this.finished = true
        }, 1000);
      },
    }
  }
</script>

<style lang="scss">
.v_my_team {
  height: 100%;
  background: #F1F1F1;
  .v-my-team-container {
    flex: 1;
    overflow: auto;
    .v-my-team-head-list {
      padding: 16px 10px;
      background: $main_color;
      .v-my-team-head-list-item {
        flex: 1;
        color: $white;
        font-size: 12px;
      }
    }
    .v-my-team-list-container {
        flex: 1;
        overflow: auto;
       .v-my-team-list {
         padding: 0 10px;
         background: #F1F1F1;
         .v-my-team-list-item {
            margin-top: 20px;
            border-radius: 8px;
            background: $white;
           .v-my-team-list-item-box {
            padding: 16px 0;
            .v-my-team-list-item-val {
              font-size: 12px;
              flex: 1;
            }
           }
         }
      }
    }
  }
}
</style>